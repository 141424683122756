import * as amplitude from '@amplitude/analytics-browser';

const isProduction = () => {
  return process.env.NODE_ENV === 'production';
}

const getAmplitudeApiKey = () => {
  return process.env.REACT_APP_AMPLITUDE_API_KEY;
}

const initAmplitude = () => {
  if (isProduction()) {
    amplitude.init(getAmplitudeApiKey(), { 'autocapture': true });
  }
}

const trackTripClickedEvent = () => {
  if (isProduction()) {
    amplitude.track('TripClicked');
  }
}

export {
  initAmplitude,
  trackTripClickedEvent
};
