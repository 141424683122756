import './Header.css';
import { ReactComponent as LogoCompanyNameIcon } from '../icons/logo-company-name.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as UserSignedInIcon } from '../icons/user-signed-in.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from './Menu';
import { isLoggedIn } from '../lib/session';

function Header() {
  const isLogged = isLoggedIn();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  
  const handleMenuButtonOnClick = () => {
    setShowMenu(true);
  }
  const onCloseMenu = () => {
    setShowMenu(false);
  }

  const goToExplorar = () => {
    navigate('/');
  }

  return (
    <div className="Header">
      <div className="header-content">
        <LogoCompanyNameIcon  onClick={goToExplorar} className="header-logo-icon"/>

        <div className="header-menu-button">
          {isLogged ?
           <UserSignedInIcon className="header-user-signed-in-icon" onClick={handleMenuButtonOnClick}/>
           :
           <MenuIcon className="header-menu-button-icon" onClick={handleMenuButtonOnClick}/>
          }
        </div>

        {showMenu && <Menu onClose={onCloseMenu}/>}
      </div>
  </div>
  );
}

export default Header;  
