import './SearchPage.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import SearchTools from '../components/SearchTools';
import TripList from '../components/TripList';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { useSearchParams } from "react-router-dom";
import { isLoggedIn, getToken } from '../lib/session';
import toast, { Toaster } from 'react-hot-toast';
import UserMustBeLoggedInModal from '../components/UserMustBeLoggedInModal';
import EnableDisableAlertsButton from '../components/EnableDisableAlertsButton';

function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const extractGroup = () => {
    const group = searchParams.get('grupo');
    if (group === 'viajeros') return 'courier';
    if (group === 'remitentes') return 'sender'
    return 'all';
  }

  const [filters, setFilters] = useState({
    group: extractGroup(),
    page: 1,
    origin_country: searchParams.get('pais_origen') || null,
    origin_city: searchParams.get('ciudad_origen') || null,
    destination_country: searchParams.get('pais_destino') || null,
    destination_city: searchParams.get('ciudad_destino') || null
  });

  const [trips, setTrips] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alertId, setAlertId] = useState(null);

  const loadAndAppendPage = (filterThatChanged) => {
    if (isLoading) return;
    const newFilters = { ...filters, ...filterThatChanged };
    const PER_PAGE = 30;
    const params = { ...newFilters, per_page: PER_PAGE };

    if (newFilters.page === 1) {
      setTrips([]);
      setHasMore(true);
    }

    const buildGroup = () => {
      const group = newFilters.group;
      if (group === 'courier') return 'viajeros';
      if (group === 'sender') return 'remitentes';
      return 'todos';
    }

    const newSearchParams = {
      grupo: buildGroup(),
      pais_origen: newFilters.origin_country,
      ciudad_origen: newFilters.origin_city,
      pais_destino: newFilters.destination_country,
      ciudad_destino: newFilters.destination_city
    }
    const newSearchParamsWithoutNulls = Object.fromEntries(Object.entries(newSearchParams).filter(([_, v]) => v != null));
    setSearchParams(newSearchParamsWithoutNulls);

    setIsLoading(true);

    const headers = isLoggedIn() ? { 'Authorization': `Bearer ${getToken()}`} : {}

    axios.get('/api/trips', { params: params, headers: headers })
      .then((response) => {
        const nextTrips = response.data.data.trips;
        const alertId = response.data.data.alert?.id;

        setTrips(prev => {
          // avoid duplicates in development because of the StrictMode
          const currentTrips = {};
          prev.forEach(trip => currentTrips[trip.id] = true);

          const strictlyNewTrips = nextTrips.filter(trip => !currentTrips[trip.id]);

          return [...prev, ...strictlyNewTrips]
        });

        setAlertId(alertId);

        setFilters(newFilters);

        if (nextTrips.length < PER_PAGE) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFilterChange = (filterThatChanged) => { return loadAndAppendPage({ ...filterThatChanged, page: 1 }) };
  const fetchNextPage = () => { return loadAndAppendPage({ page: filters.page + 1 }) };

  useEffect(() => loadAndAppendPage({}), []);

  const [numberOfAlerts, setNumberOfAlerts] = useState(null);

  useEffect(() => {
    if (!isLoggedIn()) {
      return;
    }

    axios.get('/api/alerts', { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        const alerts = response.data.data.alerts;
        setNumberOfAlerts(alerts.length);
      })
      .catch((error) => console.log(error));
  }, []);

  const onEnableAlerts = () => {
    if (!isLoggedIn()) {
      toast.success('Para habilitar alertas primero debes iniciar sesión.', { id: 'log-in-required' });
      return;
    }

    if (numberOfAlerts === 3) {
      toast.error('Haz alcanzado el límite de 3 alertas. Para habilitar una nueva, ve a la sección Alertas y elimina una de las alertas existentes.', { id: 'maximum-number-of-alerts-reached' });
      return;
    }

    const params = {
      alert: {
        group: filters.group, origin_country: filters.origin_country, origin_city: filters.origin_city,
        destination_country: filters.destination_country, destination_city: filters.destination_city
      }
    };

    axios.post(`/api/alerts/`, params, { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        setAlertId(response.data.data.alert.id);
        setNumberOfAlerts(prev => prev === null ? null : prev + 1);
        let message = 'Alertas habilitadas para la búsqueda actual.';
        if (numberOfAlerts === 2) {
          message = `${message} Haz alcanzado el límite de 3 alertas.`;
        }
        toast.success(message, { id: 'enabled' });
      })
      .catch((error) => {
        console.log(error);

        if (error.response.status === 422 && error.response.data.code === 'MAXIMUM_NUMBER_OF_ALERTS_REACHED') {
          toast.error('Haz alcanzado el límite de 3 alertas. Para habilitar una nueva, ve a la sección Alertas y elimina una de las alertas existentes.', { id: 'maximum-number-of-alerts-reached' });
          return;
        }

        toast.error('Ocurrió un error mientras se intentaban habilitar las alertas.', { id: 'error' });
      });
  };

  const onDisableAlerts = () => {
    if (!isLoggedIn()) {
      return;
    }

    axios.delete(`/api/alerts/${alertId}`, { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        setAlertId(null);
        setNumberOfAlerts(prev => prev === null ? null : prev - 1);
        toast.success('Alertas deshabilitadas para la búsqueda actual.', { id: 'disabled' });
      })
      .catch((error) => {
        console.log(error);
        toast.error('Ocurrió un error mientras se intentaban deshabilitar las alertas.', { id: 'error' });
      });
  };

  const allowClickEnableDisableAlertsButton = filters.group !== 'all' && filters.origin_country !== null && filters.destination_country !== null;

  const enableDisableAlertsButton = <EnableDisableAlertsButton
                                      isEnabled={!!alertId}
                                      onEnableAlerts={onEnableAlerts}
                                      onDisableAlerts={onDisableAlerts}
                                      numberOfAlerts={numberOfAlerts}
                                      allowClickEnableDisableAlertsButton={allowClickEnableDisableAlertsButton}/>

  return (
    <div className="SearchPage">
      <Header />

      <div className="search-page-content">

        <Toaster position="top-center" reverseOrder={false}/>
        {/* {showUserMustBeLoggedInModal && <UserMustBeLoggedInModal onClose={() => setShowUserMustBeLoggedInModal(false)}/>} */}

        <div className="search-page-search-tools">
            <SearchTools filters={filters} onFilterChange={onFilterChange} enableDisableAlertsButton={enableDisableAlertsButton}/>
        </div>

        <div className="trip-list">
          <TripList trips={trips} fetchNextPage={fetchNextPage} hasMore={hasMore} />
        </div>

        <ScrollToTopButton />

        {enableDisableAlertsButton}
      </div>

    </div>
  );
}

export default SearchPage;
