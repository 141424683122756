import './SearchTools.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Select from './Select.js';
import { createOriginOptions, createDestinationOptions, findOriginOption, findDestinationOption } from '../lib/selectOptionsCreators.js';

function SearchTools({ filters, onFilterChange }) {
  const { group, origin_country, origin_city, destination_country, destination_city } = filters;
  const [defaultOriginOption, setDefaultOriginOption] = useState(null);
  const [originOptions, setOriginOptions] = useState([]);
  const [defaultDestinationOption, setDefaultDestinationOption] = useState(null);
  const [destinationOptions, setDestinationOptions] = useState([]);

  const buildClassNameForGroupOption = (name) => {
    return `search-tools-groups-section-option ${name === group ? 'search-tools-groups-section-option-selected' : ''}`;
  };

  const onOriginChange = (option) => {
    onFilterChange(option?.value || { origin_country: null, origin_city: null });
  };

  const onDestinationChange = (option) => {
    onFilterChange(option?.value || { destination_country: null, destination_city: null });
  };


  useEffect(() => {
    axios.get('/api/countries')
      .then((response) => {
        const countries = response.data.data.countries;

        const originOptions = createOriginOptions(countries);
        setDefaultOriginOption(findOriginOption(originOptions, origin_country, origin_city));
        setOriginOptions(originOptions);
        // console.log("origin option", findOriginOption(originOptions, origin_country, origin_city));

        const destinationOptions = createDestinationOptions(countries);
        setDefaultDestinationOption(findDestinationOption(destinationOptions, destination_country, destination_city));
        setDestinationOptions(destinationOptions);
        // console.log("destination option", findDestinationOption(destinationOptions, destination_country, destination_city));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="SearchTools">
      <div className="search-tools-selects-section">
        <Select
          label="Origen"
          defaultOption={defaultOriginOption}
          options={originOptions}
          onChange={onOriginChange}/>
        
        <Select
          label="Destino"
          defaultOption={defaultDestinationOption}
          options={destinationOptions}
          onChange={onDestinationChange}/>
      </div>

      <div className="search-tools-groups-section">
        <span className={buildClassNameForGroupOption('all')} onClick={(_e) => onFilterChange({ group: 'all' })}>Todos</span>
        <span className={buildClassNameForGroupOption('courier')} onClick={(_e) => onFilterChange({ group: 'courier'})}>Quiero enviar</span>
        <span className={buildClassNameForGroupOption('sender')} onClick={(_e) => onFilterChange({ group: 'sender' })}>Soy viajero</span>
      </div>
  </div>
  );
}

export default SearchTools;
