import './TripItem.css';
// import { ReactComponent as TruckIcon } from '../icons/truck.svg';
// import { ReactComponent as AirplaneIcon } from '../icons/airplane.svg';
// import { ReactComponent as PackageIcon } from '../icons/package.svg';

// import { ReactComponent as UserIcon } from '../icons/user.svg';
// import { ReactComponent as CalendarIcon } from '../icons/calendar.svg';

function TripItem({ trip }) {
  let {
    user, source, group, date, origin_country, origin_city, destination_country, destination_city, description, created_at
  } = trip;

  if (source === 'facebook_group') {
    description = JSON.parse(description);
    user = description.author;
    created_at = description.created_at;
    description = description.content;
  }

  const origin = [origin_city, origin_country].filter((el => el)).join(', ');
  const destination = [destination_city, destination_country].filter((el => el)).join(', ');

  const timestampFormattingOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const creationDate = new Date(created_at).toLocaleDateString('es', timestampFormattingOptions);

  const dateFormattingOptions = { ...timestampFormattingOptions, timeZone: 'UTC' };
  const tripDate = new Date(date).toLocaleDateString('es', dateFormattingOptions);

  return (
    <div className="TripItem">
      <div className="title-section">
        <span className="title">{origin} → {destination}</span>
      </div>

      <div className="details-section">
        {/* <UserIcon className="trip-item-user-icon" /> */}
        {'Juan Perez Perez'}

        { date && <>
          {/* <CalendarIcon className="trip-item-calendar-icon"></CalendarIcon> */}
          {tripDate}
        </>}
      </div>
      
      <div className="description">{description}</div>


        <div className="trip-item-creation-date">
          Publicado el {creationDate}
        </div>
    </div>
  );
}
   
export default TripItem;
